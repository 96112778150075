<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        :small="small"
        color="error"
        @click="deletePlan"
      >
        <v-icon v-text="'$delete'"></v-icon>
      </v-btn>
    </template>
    {{ $t('assemblyPlanning.deletePlan') }}
  </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DeletePlan',
  props: {
    planId: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: 'all',
    },
  },
  methods: {
    ...mapActions('assemblyPlanning', ['deletePlanByPlanId']),
    async deletePlan() {
      if (await this.$root.$confirm.open(
        this.$t('assemblyPlanning.confirmDeleteTitle'),
        this.$t('assemblyPlanning.confirmDeleteSubtitle', { id: this.planId }),
      )) {
        const deleted = await this.deletePlanByPlanId({
          planId: this.planId,
          listType: this.listType,
        });
        if (this.$route.name === 'assemblyPlanDetails' && deleted) {
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>
