<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="5" xl="6">
        <plan-info />
      </v-col>
      <v-col cols="12" lg="7" xl="6">
        <v-row>
          <v-col cols="12">
            <cycletime-trend />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" lg="6">
                <rejection-pareto />
              </v-col>
              <v-col cols="12" lg="6">
                <downtime-pareto />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlanInfo from './details/PlanInfo.vue';
import CycletimeTrend from './details/CycletimeTrend.vue';
import RejectionPareto from './details/RejectionPareto.vue';
import DowntimePareto from './details/DowntimePareto.vue';

export default {
  name: 'PlanDetails',
  components: {
    PlanInfo,
    CycletimeTrend,
    RejectionPareto,
    DowntimePareto,
  },
};
</script>
