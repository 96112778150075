<template>
  <v-select
    dense
    outlined
    item-value="value"
    item-text="name"
    v-if="isMobile"
    :label="$t('assemblyPlanning.line')"
    v-model="line"
    :items="lines"
    prepend-inner-icon="mdi-crosshairs"
  ></v-select>
  <v-autocomplete
    dense
    v-else
    outlined
    item-value="value"
    item-text="name"
    :label="$t('assemblyPlanning.line')"
    v-model="line"
    :items="lines"
    prepend-inner-icon="mdi-crosshairs"
  ></v-autocomplete>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';

const FIELD_NAME = 'linename';

export default {
  name: 'LineSelection',
  computed: {
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('assemblyPlanning', ['lineList']),
    isLineFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    lines() {
      let lines = [];
      if (this.lineList && this.lineList.length) {
        if (this.lineList.length === 1) {
          lines = [...this.lineList];
        } else {
          lines = [{
            name: this.$t('assemblyPlanning.allLines'),
            value: 'All',
          }, ...this.lineList];
        }
      }
      return lines;
    },
    line: {
      get() {
        const lineFilter = this.filters && this.filters[FIELD_NAME];
        if (lineFilter) {
          return lineFilter.value;
        }
        return this.lines && this.lines.length && this.lines[0].value;
      },
      set(lineVal) {
        this.setLineFilter(lineVal);
      },
    },
  },
  created() {
    if (this.lines && this.lines.length) {
      if (this.isLineFilterInactive) {
        this.setLineFilter(this.lines[0].value);
      }
    } else {
      this.fetchMachines();
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    ...mapActions('assemblyPlanning', ['fetchMachines']),
    setLineFilter(val) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: val,
          operation: 'eq',
        },
      });
    },
  },
  watch: {
    lines(val) {
      if (val && val.length) {
        if (this.isLineFilterInactive) {
          this.setLineFilter(val[0].value);
        }
      }
    },
  },
};
</script>
